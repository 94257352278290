@font-face {
  font-family: 'Charis';
  font-weight: 700;
  src: url(./CharisSILEur-B.woff2) format('woff2'),
       url(./CharisSILEur-B.woff) format('woff'),
       url(./CharisSILEur-B.ttf) format('truetype');
}
@font-face {
  font-family: 'Charis';
  font-weight: 700;
  font-style: italic;
  src: url(./CharisSILEur-BI.woff2) format('woff2'),
       url(./CharisSILEur-BI.woff) format('woff'),
       url(./CharisSILEur-BI.ttf) format('truetype');
}
@font-face {
  font-family: 'Charis';
  font-style: italic;
  src: url(./CharisSILEur-I.woff2) format('woff2'),
       url(./CharisSILEur-I.woff) format('woff'),
       url(./CharisSILEur-I.ttf) format('truetype');
}
@font-face {
  font-family: 'Charis';
  src: url(./CharisSILEur-R.woff2) format('woff2'),
       url(./CharisSILEur-R.woff) format('woff'),
       url(./CharisSILEur-R.ttf) format('truetype');
}
