@font-face {
    font-family: 'Inter';
    font-weight: 700;
    src: url(./Inter-Bold.woff2) format('woff2'),
        url(./Inter-Bold.woff) format('woff'),
        url(./Inter-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 700;
    font-style: italic;
    src: url(./Inter-BoldItalic.woff2) format('woff2'),
        url(./Inter-BoldItalic.woff) format('woff'),
        url(./Inter-BoldItalic.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-style: italic;
    src: url(./Inter-Italic.woff2) format('woff2'),
        url(./Inter-Italic.woff) format('woff'),
        url(./Inter-Italic.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter';
    src: url(./Inter-Regular.woff2) format('woff2'),
        url(./Inter-Regular.woff) format('woff'),
        url(./Inter-Regular.ttf) format('truetype');
}
