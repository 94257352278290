.prose {
    strong {
        font-weight: 500;
    }

    ul > li {
        margin-top: 0.25em;
        margin-bottom: 0.25em;
    }

    a {
        text-decoration: underline;
    }

    h2, h3, h4 {
        @apply font-inter text-text;
    }

    a[href^="http"]::before {
        content: "";
        display: inline-block;
        width: 1em;
        height: 1em;
        background: url(./link.svg);
        background-size: contain;
    }
}

.prose::after {
    content: "";
    display: table;
    @apply clear-both;
}

img.richtext-image.full-width {
    display: block;
    width: 100%;
}

img.richtext-image.left {
    float: left;
}

img.richtext-image.right {
    float: right;
}
