@layer base {
    .font-nunito {
        font-family: 'Nunito';
    }

    .font-lato {
        font-family: 'Lato';
    }

    .font-charis {
        font-family: 'Charis';
    }

    .font-inter {
        font-family: 'Inter';
    }
}

.menu-link {
    @apply text-text px-3.5 text-center self-center;
    font-family: 'Nunito';
    font-size: 15px;

    position: relative;
}

.hc-nav-trigger {
    padding: 5px;
    width: 40px;
    height: 40px;
    left: 10px;
    border-radius: 7px;
    background-color: rgba(255, 255, 255, 0.6);
}

.hc-nav-trigger span {
    left: 5px;
}

.feature-list ul {
    list-style-image: url(./tilde.svg);
    padding-left: 16px;
    padding-top: 5px;
}

.tippy-box[data-theme="navbar"] {
    width: 200px;
    margin-top: -5px;
    background: #f2f2f2;

    a {
        display: block;
        color: #333;
        padding: 4px 8px;
    }

    a:hover {
        color: #f2f2f2;
        background: #333;
    }
}

.footer-area hr {
    border-color: #000;
}

blockquote {
    @apply text-lg;
    font-style: italic;
}

.calendar {
    width: 190px;
    margin: auto;
}

.calendar .day {
    float: left;
    width: 27px;
    height: 27px;
    background: #9dd49d;
    text-align: center;
    padding: 2px;
    position: relative;
}

.calendar .day.header {
    background: transparent;
}

.calendar .day .text, .calendar .day .element {
    position: absolute;
    top: 0;
    left: 0;
}

.calendar .day .text {
    bottom: 0;
    right: 0;
}

.calendar .day.blocked {
    background: #e97979;
}

.calendar .day.transition .element {
    width: 0;
    height: 0;
    border-top: 27px solid #e97979;
    border-right: 27px solid transparent;
}

.calendar .day.transition.blocked .element {
    border-top-color: #9dd49d;
}

.copyright .content {
    display: none;
}

.copyright:hover .content {
    display: inline-block;
}
